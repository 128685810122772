const dev = {
  url: {
    REACT_APP_DOMAIN: `https://api-dev-oea-mesecvi.heippi.com`,
    REACT_APP_FLASK: `https://api2-dev-oea-mesecvi.heippi.com`
  }
}

const prod = {
  url: {
    REACT_APP_DOMAIN: `https://api.belemdopara.org`,
    REACT_APP_FLASK: `https://api2.belemdopara.org`
  }
}

const localdev = {
  url: {
    REACT_APP_DOMAIN: `http://localhost:8080`,
    REACT_APP_FLASK: `http://localhost:4000`
  }
}

let configEnv
if (process.env.REACT_APP_NODE_ENV == 'prod') {
  configEnv = prod
} else if (process.env.REACT_APP_NODE_ENV == 'dev') {
  configEnv = dev
} else {
  configEnv = localdev
}
export const config = configEnv
